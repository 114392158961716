<template>
    <section class="activity-management">
        <div class="top-bar bg-white">
            <el-input v-model="formSearch.promotionName" placeholder="请输入活动名称" style="margin: 0 7px; width: 200px" size="small"/>
            <el-input v-model="formSearch.couponName" placeholder="请输入优惠券名称" style="margin: 0 7px; width: 200px" size="small"/>
            <el-select v-model="formSearch.status" placeholder="请选择活动状态" style="margin-right: 10px;width: 200px" size="small">
                <el-option label="正常" :value="0"/>
                <el-option label="作废" :value="1"/>
            </el-select>
            <el-button type="primary" size="small" @click="handleSearch">查询</el-button>
            <el-button size="small" @click="handleReset">重置</el-button>
            <el-button type="primary" size="small" icon="el-icon-plus" @click="saveCoupon(null)">添加优惠券</el-button>
        </div>

        <r-e-table class="bg-white" ref="orderTableRef" :dataRequest="getCouponPageListTableData" :columns="tableColumn"
                   :query="formSearch" :height="790">
            <template slot="empty">
                <el-empty/>
            </template>
            <el-table-column slot="toolbar" label="操作" width="150">
                <template slot-scope="{ row }">
                    <div class="table-tools">
                        <span class="table-btn" @click="openInfo(row)">详情</span>
                        <span class="table-btn" v-if="row.status===0" @click="saveCoupon(row)">编辑</span>
                        <span class="table-btn" v-if="row.status===0" @click="endCoupon(row)">结束</span>
                    </div>
                </template>
            </el-table-column>
        </r-e-table>

        <layer-save-coupon ref="layerSaveCoupon" @handleSearch="handleSearch"/>
    </section>
</template>

<script>
import { tableColumn } from "./data";
import { getCouponPageList, finishedCoupon } from "@/api/activity-management";
import {MessageSuccess} from "@custom/message";
export default {
    name: "coupon-manage",
    data() {
        return {
            tableColumn,
            formSearch: {
                promotionName: null,
                couponName: null,
                status: null,
            },
            loadingOptions: {
                lock: true,
                text: "Loading...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            },
        };
    },
    mounted() {

    },
    components: {
        layerSaveCoupon: ()=>import('./components/layer-save-coupon')
    },
    methods: {
        getCouponPageListTableData(params) {
            return getCouponPageList(params);
        },
        handleSearch() {
            this.$refs["orderTableRef"].getTableData();
        },
        handleReset() {
            this.formSearch = {promotionName: null, couponName: null, status: null,};
            Promise.resolve(this.formSearch).then(() => this.handleSearch());
        },
        openCoupon(data, type) {
            this.$refs["layerSaveCoupon"].openDialog(data, type);
        },
        saveCoupon(data) {
            this.openCoupon(data, data ? 2 : 1);
        },
        openInfo(data) {
            this.openCoupon(data, 0);
        },
        endCoupon(data){
            const that = this;
            const {uuid} = data;
            this.$confirm('是否永久关闭此优惠?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                const loadingOptions = that.loadingOptions;
                const loading = that.$loading(loadingOptions);
                finishedCoupon({uuid}).then(res => {
                    MessageSuccess("关闭成功");
                    that.handleSearch();
                }).finally(() => loading.close());
            }).catch(() => {
            });
        }
    },
    watch: {
    }
}
</script>


<style lang="scss" scoped>
.activity-management {
    padding: VH(15px) VW(15px);

    .top-bar {
        display: flex;
        margin: VH(10px) 0 VH(25px);
        padding: VH(15px) VW(15px);
        justify-content: flex-start;
    }

    /deep/ .r-e-table {
        .el-table .cell {
            // white-space: nowrap;
        }
    }
}
</style>